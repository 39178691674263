


































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AudioTrackV2 from '@/components/Audio/AudioTrackV2.vue'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import YoutubeAudioTrack from './YoutubeAudioTrack.vue'
import TheConfetti from '../Common/TheConfetti.vue'
import { computed, reactive } from '@vue/composition-api'
import { useGetAllTracks } from '@/hooks/audio'
import get from 'lodash.get'

@Component({
  components: {
    TheMainPage,
    AudioTrackV2,
    YoutubeAudioTrack,
    TheConfetti,
  },
  setup(props: any) {
    const isYoutube = computed(() => !!props.fromYoutube)
    const query = computed(() => get(props, 'searchValue', null))
    const allTrackParams: any = reactive({
      paginate: true,
      limit: 10,
      offset: 0,
      query,
      category: 'TRACK',
      fromYoutube: isYoutube,
    })
    const { data: allAudioTracksData, isFetching: allAudioTracksLoading } = useGetAllTracks(allTrackParams)

    return {
      allTrackParams,
      allAudioTracksData,
      allAudioTracksLoading,
    }
  },
})
export default class AllAudioPage extends Vue {
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(Boolean) showPreviewButton!: boolean
  @Prop(Boolean) showDownloadButton!: boolean
  @Prop(Boolean) fromYoutube!: boolean
  @Prop() selectedPodcast!: any
  @Prop(String) searchValue!: any

  @Getter allAudioPageNumber!: number
  @Getter multiUploadCounter!: any

  @Action setAllAudioPageNumber!: any

  pageNo: number = 1
  increment: number = 10
  timer: number = 0
  showConfetti = false
  confettiCounter = false

  isLoadingEpisodes = false

  allTrackParams!: any
  allAudioTracksData!: any
  allAudioTracksLoading!: boolean

  mounted() {
    this.allTrackParams.offset = (this.allAudioPageNumber - 1) * this.increment
  }

  get audioTracksData() {
    return get(this.allAudioTracksData, 'data', [])
  }
  get audioTracksCount() {
    return get(this.allAudioTracksData, 'count', 0)
  }

  get loaders() {
    if (this.audioTracksCount && this.allAudioPageNumber === this.pageCount) {
      const noOfLoader = +((this.audioTracksCount / 10) % 1).toFixed(1) * 10
      return noOfLoader === 0 ? new Array(10) : new Array(noOfLoader)
    } else {
      return new Array(10)
    }
  }

  get pageCount() {
    return Math.ceil(this.audioTracksCount / 10)
  }

  async OnPageNumberChanged(page: number) {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    this.timer = window.setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.setAllAudioPageNumber(page)
      }
    }, 600)
  }

  async next() {
    if (this.allAudioPageNumber + 1 <= this.pageCount) {
      this.setAllAudioPageNumber(this.allAudioPageNumber + 1)
    }
  }

  async prev() {
    if (this.allAudioPageNumber > 1) {
      this.setAllAudioPageNumber(this.allAudioPageNumber - 1)
    }
  }

  @Watch('allAudioPageNumber')
  watchPageNo() {
    this.allTrackParams.offset = (this.allAudioPageNumber - 1) * this.increment
  }

  @Watch('multiUploadCounter')
  handleMultiUploadCounter() {
    if (this.multiUploadCounter.length > 0) {
      this.confettiCounter = true
    }
    if (!this.multiUploadCounter.length && this.confettiCounter) {
      this.showConfetti = true
      this.confettiCounter = false
      setTimeout(() => {
        this.showConfetti = false
      }, 10000)
    }
  }
}
