var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c(
        "div",
        { staticClass: "bg-adori-light-gray br2 ba b--adori-gray white" },
        [
          _vm.allAudioTracksLoading
            ? _c(
                "div",
                _vm._l(_vm.loaders, function (_, index) {
                  return _c("div", {
                    key: index,
                    staticClass:
                      "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size",
                  })
                }),
                0
              )
            : _c("div", [
                _c("div", [
                  _vm.audioTracksCount === 0
                    ? _c("div", { staticClass: "tc pv4" }, [
                        _vm._v("No audios found!"),
                      ])
                    : _vm.fromYoutube
                    ? _c(
                        "div",
                        _vm._l(_vm.audioTracksData, function (track) {
                          return _c("YoutubeAudioTrack", {
                            key: track.uid,
                            attrs: {
                              sourceComponent: "ALLAUDIO-TAB",
                              audioTrack: track,
                              selectable: _vm.selectable,
                              selectionType: _vm.selectionType,
                              editable: _vm.editable,
                              podcast: _vm.selectedPodcast,
                              showEditButtons: _vm.showEditButtons,
                              showPreviewButton: _vm.showPreviewButton,
                              showDownloadButton: _vm.showDownloadButton,
                            },
                          })
                        }),
                        1
                      )
                    : _c(
                        "div",
                        _vm._l(_vm.audioTracksData, function (track) {
                          return _c("AudioTrackV2", {
                            key: track.uid,
                            attrs: {
                              sourceComponent: "ALLAUDIO-TAB",
                              audioTrack: track,
                              selectable: _vm.selectable,
                              selectionType: _vm.selectionType,
                              editable: _vm.editable,
                              podcast: _vm.selectedPodcast,
                              showEditButtons: _vm.showEditButtons,
                              showPreviewButton: _vm.showPreviewButton,
                              showDownloadButton: _vm.showDownloadButton,
                            },
                          })
                        }),
                        1
                      ),
                ]),
              ]),
          _c(
            "div",
            { staticClass: "flex justify-end pv3 ph4 bt b--adori-gray bw1" },
            [
              _c("BaseButtonRed", {
                staticClass: "mr3 youtube-link",
                attrs: {
                  text: "Prev",
                  onClick: _vm.prev,
                  disabled: _vm.allAudioPageNumber === 1,
                },
              }),
              _c("input", {
                staticClass: "bg-adori-light-gray adori-gray br2 w-38",
                attrs: { type: "text" },
                domProps: { value: _vm.allAudioPageNumber },
                on: {
                  input: function ($event) {
                    _vm.OnPageNumberChanged(parseInt($event.target.value))
                  },
                },
              }),
              _c("span", { staticClass: "pa2 silver" }, [
                _vm._v("/ " + _vm._s(_vm.pageCount)),
              ]),
              _c("BaseButtonRed", {
                staticClass: "ml2 youtube-link",
                attrs: {
                  text: "Next",
                  onClick: _vm.next,
                  disabled: _vm.allAudioPageNumber === _vm.pageCount,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.showConfetti ? _c("TheConfetti") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }